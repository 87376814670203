var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-building-overview", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "app-welcome-message" },
            [
              _c("h1", [_vm._v("Erkende maatregelen toevoegen")]),
              _c("p", [
                _vm._v(
                  "Aan meerdere gebouwen kunnen gelijktijdig de erkende maatregelen toegevoegd worden."
                ),
              ]),
              _setup.buildingCount >= 1000
                ? _c(
                    "v-alert",
                    {
                      attrs: {
                        dense: "",
                        text: "",
                        outlined: "",
                        type: "warning",
                        icon: "mdi-alert",
                      },
                    },
                    [_vm._v("Aantal is beperkt tot 1000")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-stepper",
        {
          attrs: { flat: "" },
          model: {
            value: _setup.currentStep,
            callback: function ($$v) {
              _setup.currentStep = $$v
            },
            expression: "currentStep",
          },
        },
        [
          _c(
            "v-stepper-header",
            [
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 1, step: "1" } },
                [_vm._v(" Selectie ")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 2, step: "2" } },
                [_vm._v(" Selectie categorieën ")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 3, step: "3" } },
                [_vm._v(" Bevestiging ")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 4, step: "4" } },
                [_vm._v(" Resultaat ")]
              ),
            ],
            1
          ),
          _c("br"),
          _c(
            "v-stepper-items",
            [
              _c(
                "v-stepper-content",
                { staticClass: "pa-0", attrs: { step: "1" } },
                [
                  _c("h3", [
                    _vm._v(
                      "Selecteer de gebouwen waaraan de maatregelen toegevoegd moeten worden"
                    ),
                  ]),
                  _c(_setup.BuildingGrid, {
                    attrs: {
                      token: _setup.token,
                      "hide-details": "",
                      "hide-score": "",
                      "show-select": "",
                    },
                    on: {
                      "changed-selection": _setup.changedSelection,
                      "fetch-data-complete": _setup.buildingsLoaded,
                    },
                  }),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: {
                                    disabled:
                                      _setup.selectedBuildings.length < 1,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _setup.currentStep = 2
                                    },
                                  },
                                },
                                [_vm._v(" Volgende stap")]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                { attrs: { text: "", to: _setup.returnPage } },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-cancel")]
                                  ),
                                  _vm._v(" Annuleren "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { staticClass: "pa-0", attrs: { step: "2" } },
                [
                  _c("h3", [
                    _vm._v(
                      "Selecteer de maatregelcategorieën welke van toepassing zijn"
                    ),
                  ]),
                  _c(_setup.CertifiedCategories, {
                    attrs: {
                      selectedCategoriesProp: _setup.selectedCategoriesProp,
                    },
                    on: { "changed-selection": _setup.categorySelected },
                  }),
                  _c("div", { staticClass: "pt-3" }, [
                    _vm._v(
                      "* De maatregelen uit de niet geselecteerde categorieën zullen ook niet ingeladen worden"
                    ),
                  ]),
                  _c(
                    "v-container",
                    { attrs: { "pt-5": "", fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: { disabled: !_setup.changeAny },
                                  on: {
                                    click: function ($event) {
                                      _setup.currentStep = 3
                                    },
                                  },
                                },
                                [_vm._v(" Volgende stap")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _setup.currentStep = 1
                                    },
                                  },
                                },
                                [_vm._v(" Vorige stap ")]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                { attrs: { text: "", to: _setup.returnPage } },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-cancel")]
                                  ),
                                  _vm._v(" Annuleren "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { staticClass: "pa-0", attrs: { step: "3" } },
                [
                  _c("h3", { staticClass: "pb-3" }, [_vm._v("Bevestiging")]),
                  _vm._v(" Voor "),
                  _c("strong", [
                    _vm._v(_vm._s(_setup.selectedBuildings.length)),
                  ]),
                  _vm._v(
                    " gebouwen zullen de erkende maatregelen worden ingeladen."
                  ),
                  _c("br"),
                  _vm._v(" Voor "),
                  _c("strong", [
                    _vm._v(_vm._s(_setup.selectedCategories.length)),
                  ]),
                  _vm._v(
                    " categorieën zullen deze maatregelen de status '(Nog) niet uitgevoerd' krijgen. Voor de resterende categoriën zullen geen maatregelen ingeladen worden. "
                  ),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: { disabled: _setup.applyBusy },
                                  on: { click: _setup.applyMeasures },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-checkbox-marked-circle")]
                                  ),
                                  _vm._v(" Toepassen "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _setup.currentStep = 2
                                    },
                                  },
                                },
                                [_vm._v(" Vorige stap ")]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                { attrs: { text: "", to: _setup.returnPage } },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-cancel")]
                                  ),
                                  _vm._v(" Annuleren "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { staticClass: "pa-0", attrs: { step: "4" } },
                [
                  _c("h3", { staticClass: "pb-3" }, [_vm._v("Resultaat")]),
                  _vm._v(" Maatregelen toevoegen aan "),
                  _c("strong", [
                    _vm._v(_vm._s(_setup.selectedBuildings.length)),
                  ]),
                  _vm._v(" gebouwen "),
                  _setup.applyBusy
                    ? _c("span", { staticClass: "loadingDots" }, [
                        _vm._v("wordt toegepast "),
                      ])
                    : _c("span", [_vm._v("is toegepast.")]),
                  !_setup.applyBusy && _setup.appliedResult
                    ? _c(
                        "v-list",
                        _vm._l(_setup.appliedResult, function (item) {
                          return _c(
                            "v-list-item",
                            { key: item.code },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  item.code == "Success"
                                    ? _c("v-list-item-title", [
                                        _vm._v(
                                          "- aantal gebouwen waaraan maatregelen zijn toegevoegd: " +
                                            _vm._s(item.message)
                                        ),
                                      ])
                                    : item.code == "NoContent"
                                    ? _c("v-list-item-title", [
                                        _vm._v("- aantal gebouwen waaraan "),
                                        _c("strong", [_vm._v("geen")]),
                                        _vm._v(
                                          " maatregelen zijn toegevoegd: " +
                                            _vm._s(item.message)
                                        ),
                                      ])
                                    : item.code == "Error"
                                    ? _c("v-list-item-title", [
                                        _vm._v("- " + _vm._s(item.message)),
                                      ])
                                    : _c("v-list-item-title", [
                                        _vm._v(
                                          "- " +
                                            _vm._s(item.code) +
                                            " : " +
                                            _vm._s(item.message)
                                        ),
                                      ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: {
                                    disabled: _setup.applyBusy,
                                    to: _setup.returnPage,
                                  },
                                },
                                [_vm._v(" Klaar ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _setup.currentStep = 3
                                    },
                                  },
                                },
                                [_vm._v(" Vorige stap ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }