<script lang="ts" setup>
import { ref, computed, getCurrentInstance } from "vue";
import BuildingGrid from "@/components/app/buildings/BuildingGrid.vue";
import CertifiedCategories from "@/components/app/measures/CertifiedCategories.vue";
//import { getBuildingsFilterToken } from "@/services/api/token.api";
import { FilterTokenName } from "@/stores/filtertoken";
import handleFilterTokens27 from "@/composables/handleFilterTokens27";
import { addCertifiedMeasures, severityCode } from "@/services/api/building.api";

const { token } = handleFilterTokens27(FilterTokenName.Buildings);

const applyBusy = ref(false);
const currentStep = ref(1);

const selectedBuildings = ref([]);
const selectedCategories = ref([]);
const selectedCategoriesProp = ref([]);

const buildingCount = ref(0);
const appliedResult = ref(undefined);

const returnPage = { name: "BuildingOverview" };

const changeAny = computed(() => true);

function changedSelection(payload) {
  console.log("BuildingsAddCertified changedSelection ", payload);
  selectedBuildings.value = [...payload.selected];
}

function buildingsLoaded(payload) {
  buildingCount.value = payload.count;
}

function categorySelected(payload) {
  console.log("BuildingsAddCertified, categorySelected ", payload);
  selectedCategories.value = [...payload.selected];
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function applyMeasures() {
  applyBusy.value = true;
  currentStep.value = 4;
  console.log("BuildingsAddCertified applyMeasures ", selectedBuildings.value, selectedCategories.value);

  const ids = selectedBuildings.value.map((x) => x.id);
  var response = await addCertifiedMeasures(ids, selectedCategories.value);
  console.log("BuildingsAddCertified applyMeasures ", response);

  if (response.severity <= severityCode.warning) {
    appliedResult.value = [...response.data.results];
  } else {
    appliedResult.value = [{ code: "Error", message: "Probleem met muteren maatregelen => " + response.message }];
    proxy.$toaster.error(`Probleem met muteren maatregelen: ${response.message}`);
  }
  // console.log("BuildingsAddCertified applyMeasures ", appliedResult.value);
  applyBusy.value = false;
}
</script>

<template>
  <v-container fluid class="app-building-overview">
    <v-row>
      <v-col class="app-welcome-message">
        <h1>Erkende maatregelen toevoegen</h1>
        <p>Aan meerdere gebouwen kunnen gelijktijdig de erkende maatregelen toegevoegd worden.</p>
        <v-alert v-if="buildingCount >= 1000" dense text outlined type="warning" icon="mdi-alert">Aantal is beperkt tot 1000</v-alert>
      </v-col>
    </v-row>

    <v-stepper v-model="currentStep" flat>
      <v-stepper-header>
        <v-stepper-step :complete="currentStep > 1" step="1"> Selectie </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 2" step="2"> Selectie categorieën </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 3" step="3"> Bevestiging </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 4" step="4"> Resultaat </v-stepper-step>
      </v-stepper-header>
      <br />
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <h3>Selecteer de gebouwen waaraan de maatregelen toegevoegd moeten worden</h3>
          <building-grid :token="token" hide-details hide-score show-select @changed-selection="changedSelection" @fetch-data-complete="buildingsLoaded"></building-grid>
          <v-container fluid>
            <v-row>
              <v-col cols="auto" class="form-group">
                <v-btn :disabled="selectedBuildings.length < 1" class="primary" @click="currentStep = 2"> Volgende stap</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn text :to="returnPage">
                  <v-icon dark left>mdi-cancel</v-icon>
                  Annuleren
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="2" class="pa-0">
          <h3>Selecteer de maatregelcategorieën welke van toepassing zijn</h3>
          <certified-categories :selectedCategoriesProp="selectedCategoriesProp" @changed-selection="categorySelected"></certified-categories>
          <div class="pt-3">* De maatregelen uit de niet geselecteerde categorieën zullen ook niet ingeladen worden</div>
          <v-container pt-5 fluid>
            <v-row>
              <v-col cols="auto" class="form-group">
                <v-btn :disabled="!changeAny" class="primary" @click="currentStep = 3"> Volgende stap</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn text @click="currentStep = 1"> Vorige stap </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn text :to="returnPage">
                  <v-icon dark left>mdi-cancel</v-icon>
                  Annuleren
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>

        <v-stepper-content step="3" class="pa-0">
          <h3 class="pb-3">Bevestiging</h3>
          Voor <strong>{{ selectedBuildings.length }}</strong> gebouwen zullen de erkende maatregelen worden ingeladen.<br />
          Voor <strong>{{ selectedCategories.length }}</strong> categorieën zullen deze maatregelen de status '(Nog) niet uitgevoerd' krijgen. Voor de resterende categoriën zullen geen maatregelen
          ingeladen worden.
          <v-container fluid>
            <v-row>
              <v-col cols="auto" class="form-group">
                <v-btn :disabled="applyBusy" class="primary" @click="applyMeasures">
                  <v-icon dark left>mdi-checkbox-marked-circle</v-icon>
                  Toepassen
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn text @click="currentStep = 2"> Vorige stap </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn text :to="returnPage">
                  <v-icon dark left>mdi-cancel</v-icon>
                  Annuleren
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="4" class="pa-0">
          <h3 class="pb-3">Resultaat</h3>
          Maatregelen toevoegen aan <strong>{{ selectedBuildings.length }}</strong> gebouwen
          <span v-if="applyBusy" class="loadingDots">wordt toegepast </span>
          <span v-else>is toegepast.</span>
          <v-list v-if="!applyBusy && appliedResult">
            <v-list-item v-for="item in appliedResult" v-bind:key="item.code">
              <v-list-item-content>
                <v-list-item-title v-if="item.code == 'Success'">- aantal gebouwen waaraan maatregelen zijn toegevoegd: {{ item.message }}</v-list-item-title>
                <v-list-item-title v-else-if="item.code == 'NoContent'">- aantal gebouwen waaraan <strong>geen</strong> maatregelen zijn toegevoegd: {{ item.message }}</v-list-item-title>
                <v-list-item-title v-else-if="item.code == 'Error'">- {{ item.message }}</v-list-item-title>
                <v-list-item-title v-else>- {{ item.code }} : {{ item.message }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-container fluid>
            <v-row>
              <v-col cols="auto" class="form-group">
                <v-btn :disabled="applyBusy" class="primary" :to="returnPage"> Klaar </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn text @click="currentStep = 3"> Vorige stap </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>
